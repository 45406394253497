import React from "react";

import { FilterControlWrapper, FilterLabel } from "components/common/Creatives";
import { Filter } from "components/common/filters/Filter";
import { ChipsSingle, Option } from "components/forms/Chips";
import { ChoosableRenderingOptions } from "repo/CreativeData";
import { extractPrefixedParameters } from "util/UrlSearchParamsHelpers";

type OptionWithOrder = Option & { order: number };

export const createRenderingOptionsFilter = (ignoredRenderingOptions: (keyof ChoosableRenderingOptions)[]): Filter => ({
  render: ({ creativesFilters, updateCreativesFilters, initialCreatives }) => {
    const flatOptions = getFlatOptions(initialCreatives.map((creative) => creative.choosableRenderingOptions));
    for (const key of ignoredRenderingOptions) {
      delete flatOptions[key];
    }

    return Object.entries(flatOptions).map(([key, values]) => (
      <React.Fragment key={key}>
        <FilterLabel>{getLabel(key)}:</FilterLabel>
        <FilterControlWrapper>
          <ChipsSingle
            options={(
              [
                {
                  key: null,
                  text: "Any",
                },
              ] as Option[]
            ).concat(values.map(getChipOption).sort((a, b) => a.order - b.order))}
            keyEnabled={(creativesFilters.localParameters.renderingOptions || {})[key] || null}
            keyEnabledChanged={(newValue) => {
              const renderingOptions = { ...(creativesFilters.localParameters.renderingOptions || {}) };
              if (newValue !== null) {
                renderingOptions[key] = newValue as string;
              } else {
                delete renderingOptions[key];
              }

              updateCreativesFilters({
                ...creativesFilters,
                localParameters: {
                  ...creativesFilters.localParameters,
                  renderingOptions: renderingOptions,
                },
              });
            }}
          />
        </FilterControlWrapper>
      </React.Fragment>
    ));
  },
  filterCreatives: () => {
    return true;
  },
  searchFromLocalParameters: (search, localParameters) => {
    Object.entries(localParameters.renderingOptions ?? {}).forEach(([key, value]) => {
      search.set(`ro_${key}`, value);
    });

    return search;
  },
  searchToLocalParameters: (search, localParameters) => {
    return {
      ...localParameters,
      renderingOptions: extractPrefixedParameters(search, "ro_"),
    };
  },
  getRequestParams: (search, creativesFilters) => {
    Object.entries(creativesFilters.localParameters.renderingOptions || {}).forEach(([key, value]) => {
      search.set(`ro_${key}`, value);
    });

    return search;
  },
});

export const getFlatOptions = (options: ChoosableRenderingOptions[]) =>
  options.reduce<ChoosableRenderingOptions>((acc, curr) => {
    for (const [key, values] of Object.entries(curr)) {
      acc[key] = Array.from(new Set([...(acc[key] || []), ...values]));
    }
    return acc;
  }, {});

const LABELS: Record<string, string> = {
  offerPriceOn: "Price",
  jumpingFrameOn: "Animation",
  offerPriceOldOn: "Price (old version)",
};

function getLabel(key: string): string {
  return LABELS[key] || key;
}

const BOOL_OPTIONS: Record<string, Omit<OptionWithOrder, "key">> = {
  true: {
    text: "Enabled",
    order: 1,
  },
  false: {
    text: "Disabled",
    order: 2,
  },
};

export function getChipOption(key: string): OptionWithOrder {
  const config = BOOL_OPTIONS[key] || {
    text: key.replaceAll("_", " "),
    order: -1,
  };

  return {
    ...config,
    key,
  };
}
