import React from "react";
import { Navigate, Route, Routes, useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";

import { CreativesFilters, LocalParameters } from "components/common/Creatives";
import ErrorBoundary from "components/common/ErrorBoundary";
import { FILTERS } from "components/common/filters/Filter";
import Footer from "components/layout/Footer";
import { adlookTheme, rtbTheme } from "components/layout/theme";
import TopBar from "components/layout/TopBar";
import CreativesByHashes from "components/pages/CreativesByHashes";
import CreativesByPackIdentifier from "components/pages/CreativesByPackIdentifier";
import Index from "components/pages/Index";
import { useAdlookTheme } from "hooks/theme";
import {
  CommonParameters,
  parseSizesString,
  parseToNumberList,
  searchFromCommonParameters,
  searchFromSizes,
  searchToCommonParameters,
  searchToSizes,
  serializeNumberList,
  serializeSizesString,
  Size,
} from "repo/CreativeData";
import { mergeUrlSearchParams } from "util/UrlSearchParamsHelpers";

const App: React.FC = () => {
  const [isAdlookTheme] = useAdlookTheme();

  return (
    <ThemeProvider theme={isAdlookTheme ? adlookTheme : rtbTheme}>
      <Container>
        <TopBar />
        <Layout>
          <Routes>
            <Route path="/creatives/:hashes" element={<CreativesByHashesRoute />} />
            <Route path="/packs/:advertiserHash/:packIdentifiers" element={<CreativesByPackIdentifierRoute />} />
            <Route path="/" element={<IndexRoute />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Layout>
        <Footer />
      </Container>
    </ThemeProvider>
  );
};

export default App;

const CreativesByHashesRoute: React.FC = () => {
  const { hashes: hashesParam } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const hashes = hashesParam!
    .split(",")
    .map((hash: string) => hash.trim())
    .filter((hash: string) => !!hash);

  const commonLocalParametersSizes = searchToCreativesFilters(searchParams);

  return (
    <ErrorBoundary>
      <CreativesByHashes
        hashes={hashes}
        creativesFilters={commonLocalParametersSizes}
        updateCreativesFilters={(newCommonLocalParametersSizes) => {
          setSearchParams(searchFromCreativesFilters(newCommonLocalParametersSizes));
        }}
      />
    </ErrorBoundary>
  );
};

const CreativesByPackIdentifierRoute: React.FC = () => {
  const { advertiserHash, packIdentifiers } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const creativesFilters = searchToCreativesFilters(searchParams);

  return (
    <ErrorBoundary>
      <CreativesByPackIdentifier
        advertiserHash={advertiserHash!}
        packIdentifiers={parsePackIdentifiers(packIdentifiers!)}
        creativesFilters={creativesFilters}
        updateCreativesFilters={(filters) => {
          setSearchParams(searchFromCreativesFilters(filters));
        }}
      />
    </ErrorBoundary>
  );
};

const parsePackIdentifiers = (packIdentifiers: string) =>
  packIdentifiers!
    .split(",")
    .map((packIdentifier: string) => packIdentifier.trim())
    .filter((packIdentifier: string) => !!packIdentifier);

const IndexRoute: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Index
      redirectToHashes={(hashes: string[], commonParameters: CommonParameters, sizes: Size[] | undefined) => {
        navigate({
          pathname: `/creatives/${hashes.join(",")}`,
          search: searchFromCreativesFilters({
            commonParameters,
            sizes,
            localParameters: localParametersDefault(),
          }).toString(),
        });
      }}
      redirectToPackIdentifier={(
        advertiserHash: string,
        packIdentifiers: string[],
        commonParameters: CommonParameters,
        sizes: Size[] | undefined,
      ) => {
        navigate({
          pathname: `/packs/${advertiserHash}/${packIdentifiers.join(",")}`,
          search: searchFromCreativesFilters({
            commonParameters,
            sizes,
            localParameters: localParametersDefault(),
          }).toString(),
        });
      }}
    />
  );
};

function localParametersDefault(): LocalParameters {
  return {
    sizesFilter: undefined,
    offersNumberFilter: undefined,
    maxPreviews: undefined,
  };
}

function searchFromLocalParameters(localParameters: LocalParameters): URLSearchParams {
  let search = new URLSearchParams();

  if (localParameters.sizesFilter !== undefined) {
    search.set("sizes_filter", serializeSizesString(localParameters.sizesFilter));
  }

  if (localParameters.offersNumberFilter !== undefined) {
    search.set("offers_number_filter", serializeNumberList(localParameters.offersNumberFilter));
  }

  if (localParameters.maxPreviews !== undefined) {
    search.set("max_previews", localParameters.maxPreviews != null ? localParameters.maxPreviews.toString() : "");
  }

  FILTERS.forEach((filter) => {
    search = filter.searchFromLocalParameters(search, localParameters);
  });

  return search;
}

function searchToLocalParameters(search: URLSearchParams): LocalParameters {
  const sizesFilter = search.get("sizes_filter");
  const maxPreviews = search.get("max_previews");
  const offersNumberFilter = search.get("offers_number_filter");

  let localParameters: LocalParameters = {
    sizesFilter: undefined,
    offersNumberFilter: undefined,
    maxPreviews: undefined,
  };

  if (sizesFilter !== null) {
    localParameters.sizesFilter = parseSizesString(sizesFilter);
  }

  if (offersNumberFilter !== null) {
    localParameters.offersNumberFilter = parseToNumberList(offersNumberFilter);
  }

  if (maxPreviews !== null) {
    if (maxPreviews !== "") {
      const maxPreviewsInt = parseInt(maxPreviews);
      if (Number.isInteger(maxPreviewsInt) && maxPreviewsInt > 0) {
        localParameters.maxPreviews = maxPreviewsInt;
      }
    } else {
      localParameters.maxPreviews = null;
    }
  }

  FILTERS.forEach((filter) => {
    localParameters = filter.searchToLocalParameters(search, localParameters);
  });

  return localParameters;
}

function searchFromCreativesFilters(filters: CreativesFilters): URLSearchParams {
  return mergeUrlSearchParams(
    searchFromCommonParameters(filters.commonParameters),
    searchFromSizes(filters.sizes),
    searchFromLocalParameters(filters.localParameters),
  );
}

function searchToCreativesFilters(search: URLSearchParams): CreativesFilters {
  const commonParameters = searchToCommonParameters(search);
  const sizes = searchToSizes(search);
  const localParameters = searchToLocalParameters(search);
  return {
    commonParameters,
    sizes,
    localParameters,
  };
}

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Layout = styled.div`
  flex: auto;
`;
