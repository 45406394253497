import { FilterControlWrapper, FilterLabel } from "components/common/Creatives";
import { Filter } from "components/common/filters/Filter";
import { getFlatOptions } from "components/common/filters/RenderingOptionsFilter";
import { ChipsSingle } from "components/forms/Chips";
import { ChoosableRenderingOptions } from "repo/CreativeData";

export const createAnimationFilter = (renderingOption: keyof ChoosableRenderingOptions): Filter => ({
  render: ({ initialCreatives, creativesFilters, updateCreativesFilters }) => {
    const renderingOptions = getFlatOptions(initialCreatives.map((creative) => creative.choosableRenderingOptions));

    const hasFeature = initialCreatives.some((creative) => !!creative.features?.animation);
    const hasRenderingOption = renderingOptions[renderingOption];

    const isFeatureActive = !!creativesFilters.localParameters.features?.animation;
    const isRenderingOptionActive = !!creativesFilters.localParameters.renderingOptions?.[renderingOption];

    if (!hasFeature && !isFeatureActive && !hasRenderingOption && !isRenderingOptionActive) {
      return null;
    }

    const featureAnimations = initialCreatives
      .filter((creative) => creative.features?.animation)
      .map((creative) => creative.features?.animation ?? "");
    const renderingOptionAnimations = (renderingOptions[renderingOption] || []).map(toNormalizedOption);
    const animations = [...new Set([...featureAnimations, ...renderingOptionAnimations])];

    const getKeyEnabled = () => {
      if (isFeatureActive) {
        return creativesFilters.localParameters.features?.animation ?? null;
      }

      if (isRenderingOptionActive) {
        return toNormalizedOption(creativesFilters.localParameters.renderingOptions![renderingOption]);
      }

      return null;
    };

    return (
      <>
        <FilterLabel>Animation type:</FilterLabel>
        <FilterControlWrapper>
          <ChipsSingle
            options={[
              { key: null, text: "Any" },
              ...animations.map((animation) => ({
                key: animation,
                text: animation.replaceAll("_", " "),
              })),
            ]}
            keyEnabled={getKeyEnabled()}
            keyEnabledChanged={(key) => {
              const filters = {
                ...creativesFilters,
              };

              filters.localParameters = {
                ...filters.localParameters,
                features: {
                  ...filters.localParameters.features,
                  animation: featureAnimations.includes(`${key}`) ? `${key}` : undefined,
                },
              };

              if (renderingOptionAnimations.includes(`${key}`)) {
                filters.localParameters = {
                  ...filters.localParameters,
                  renderingOptions: {
                    ...filters.localParameters.renderingOptions,
                    [renderingOption]: toRenderingOption(`${key}`),
                  },
                };
              } else {
                delete filters.localParameters.renderingOptions?.[renderingOption];
              }

              updateCreativesFilters(filters);
            }}
          />
        </FilterControlWrapper>
      </>
    );
  },
  filterCreatives: (creative, creativesFilters) => {
    if (creativesFilters.localParameters.features?.animation === undefined) {
      return true;
    }

    const renderingOptions = creative.choosableRenderingOptions[renderingOption] ?? [];
    const hasRenderingOption = renderingOptions.includes(
      creativesFilters.localParameters.renderingOptions?.[renderingOption] ?? "",
    );

    return hasRenderingOption || creative.features?.animation === creativesFilters.localParameters.features.animation;
  },
  searchFromLocalParameters: (search, localParameters) => {
    if (localParameters.features?.animation) {
      search.set("features_animation", localParameters.features.animation);
    }

    if (localParameters.renderingOptions?.[renderingOption] !== undefined) {
      search.set(`ro_${renderingOption}`, localParameters.renderingOptions[renderingOption]);
    }

    return search;
  },
  searchToLocalParameters: (search, localParameters) => {
    const feature = search.get(`features_animation`);
    const option = search.get(`ro_${renderingOption}`);

    if (feature) {
      localParameters.features = {
        ...localParameters.features,
        animation: feature,
      };
    }

    if (option) {
      localParameters.renderingOptions = {
        ...localParameters.renderingOptions,
        [renderingOption]: option,
      };
    }

    return localParameters;
  },
  getRequestParams: (search, creativesFilters) => {
    if (creativesFilters.localParameters.renderingOptions?.[renderingOption] !== undefined) {
      search.set(`ro_${renderingOption}`, creativesFilters.localParameters.renderingOptions[renderingOption]);
    }

    return search;
  },
});

const toNormalizedOption = (option: string) => option.replaceAll("-", "_");
const toRenderingOption = (option: string) => option.replaceAll("_", "-");
