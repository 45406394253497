import styled from "styled-components";

import { FilterControlWrapper, FilterLabel } from "components/common/Creatives";
import { Filter } from "components/common/filters/Filter";
import { getFlatOptions } from "components/common/filters/RenderingOptionsFilter";
import { ChipsSingle } from "components/forms/Chips";
import { ChoosableRenderingOptions, CreativeFeatures } from "repo/CreativeData";
import { parseBooleanParameter, serializeBooleanParameter } from "util/UrlSearchParamsHelpers";

export const createBooleanFilter = (
  featureName: keyof Pick<CreativeFeatures, "name" | "button" | "price" | "slider">,
  renderingOption: keyof ChoosableRenderingOptions,
): Filter => ({
  render: ({ initialCreatives, creativesFilters, updateCreativesFilters }) => {
    const renderingOptions = getFlatOptions(initialCreatives.map((creative) => creative.choosableRenderingOptions));

    const hasFeature = initialCreatives.some((creative) => creative.features?.[featureName] !== undefined);
    const hasRenderingOption = renderingOptions[renderingOption];

    const isFeatureActive = creativesFilters.localParameters.features?.[featureName] !== undefined;
    const isRenderingOptionActive = creativesFilters.localParameters.renderingOptions?.[renderingOption] !== undefined;

    if (!hasFeature && !isFeatureActive && !hasRenderingOption && !isRenderingOptionActive) {
      return null;
    }

    const getKeyEnabled = () => {
      if (isFeatureActive) {
        return serializeBooleanParameter(creativesFilters.localParameters.features![featureName]!);
      }

      if (isRenderingOptionActive) {
        return creativesFilters.localParameters.renderingOptions![renderingOption];
      }

      return null;
    };

    return (
      <>
        <Label>{featureName}:</Label>
        <FilterControlWrapper>
          <ChipsSingle
            options={[
              { key: null, text: "Any" },
              { key: "true", text: "Enabled" },
              { key: "false", text: "Disabled" },
            ]}
            keyEnabled={getKeyEnabled()}
            keyEnabledChanged={(key) => {
              const filters = {
                ...creativesFilters,
              };

              if (key !== null) {
                if (hasFeature) {
                  filters.localParameters = {
                    ...filters.localParameters,
                    features: {
                      ...filters.localParameters.features,
                      [featureName]: parseBooleanParameter(`${key}`),
                    },
                  };
                }

                if (hasRenderingOption) {
                  filters.localParameters = {
                    ...filters.localParameters,
                    renderingOptions: {
                      ...filters.localParameters.renderingOptions,
                      [renderingOption]: `${key}`,
                    },
                  };
                }
              } else {
                delete filters.localParameters.features?.[featureName];
                delete filters.localParameters.renderingOptions?.[renderingOption];
              }

              updateCreativesFilters(filters);
            }}
          />
        </FilterControlWrapper>
      </>
    );
  },
  filterCreatives: (creative, creativesFilters) => {
    if (creativesFilters.localParameters.features?.[featureName] === undefined) {
      return true;
    }

    const renderingOptions = creative.choosableRenderingOptions[renderingOption] ?? [];
    const hasRenderingOption = renderingOptions.includes(
      creativesFilters.localParameters.renderingOptions?.[renderingOption] ?? "",
    );

    return (
      hasRenderingOption || creative.features?.[featureName] === creativesFilters.localParameters.features[featureName]
    );
  },
  searchFromLocalParameters: (search, localParameters) => {
    if (localParameters.features?.[featureName] !== undefined) {
      search.set(`features_${featureName}`, serializeBooleanParameter(localParameters.features[featureName]));
    }

    if (localParameters.renderingOptions?.[renderingOption] !== undefined) {
      search.set(`ro_${renderingOption}`, localParameters.renderingOptions[renderingOption]);
    }

    return search;
  },
  searchToLocalParameters: (search, localParameters) => {
    const feature = search.get(`features_${featureName}`);
    const option = search.get(`ro_${renderingOption}`);

    if (feature !== null) {
      localParameters.features = {
        ...localParameters.features,
        [featureName]: parseBooleanParameter(feature),
      };
    }

    if (option !== null) {
      localParameters.renderingOptions = {
        ...localParameters.renderingOptions,
        [renderingOption]: option,
      };
    }

    return localParameters;
  },
  getRequestParams: (search, creativesFilters) => {
    if (creativesFilters.localParameters.renderingOptions?.[renderingOption] !== undefined) {
      search.set(`ro_${renderingOption}`, creativesFilters.localParameters.renderingOptions[renderingOption]);
    }

    return search;
  },
});

const Label = styled(FilterLabel)`
  text-transform: capitalize;
`;
