import { CreativesFilters } from "components/common/Creatives";
import { FILTERS } from "components/common/filters/Filter";
import { get } from "svc/Api";
import {
  extractPrefixedParameters,
  mergeUrlSearchParams,
  parseBooleanParameter,
  serializeBooleanParameter,
} from "util/UrlSearchParamsHelpers";

export interface CommonParameters {
  subcampaigns?: string[];
  positionedOffers?: { [key: number]: string };
  unpositionedOffers?: string[];
  disableRedirectPreview?: boolean;
  customLandingUrl?: string;
  ssp?: string;
  techtoolsSwappableUploadId?: string;
  creativeTemplateParams?: { [key: string]: string };
  specialOffers?: SpecialOffer[];
  isPaapi?: boolean;
}

export function searchFromCommonParameters(commonParameters: CommonParameters): URLSearchParams {
  const search = new URLSearchParams();
  if (commonParameters.subcampaigns !== undefined) {
    search.set("subcampaigns", commonParameters.subcampaigns.join(","));
  }
  if (commonParameters.positionedOffers !== undefined) {
    Object.entries(commonParameters.positionedOffers).forEach(([key, value]) => {
      search.set(`offer${key}`, value);
    });
  }
  if (commonParameters.unpositionedOffers !== undefined) {
    search.set("offers", commonParameters.unpositionedOffers.join(","));
  }
  if (commonParameters.disableRedirectPreview !== undefined) {
    search.set("disable_redirect_preview", serializeBooleanParameter(commonParameters.disableRedirectPreview));
  }
  if (commonParameters.customLandingUrl !== undefined) {
    search.set("custom_landing_url", commonParameters.customLandingUrl);
  }
  if (commonParameters.ssp !== undefined) {
    search.set("ssp", commonParameters.ssp);
  }
  if (commonParameters.techtoolsSwappableUploadId !== undefined) {
    search.set("techtools_swappable_upload_id", commonParameters.techtoolsSwappableUploadId);
  }
  if (commonParameters.creativeTemplateParams !== undefined) {
    Object.entries(commonParameters.creativeTemplateParams).forEach(([key, value]) => {
      search.set(`ctp_${key}`, value);
    });
  }
  if (commonParameters.specialOffers !== undefined) {
    search.set("special_offers", serializeSpecialOffers(commonParameters.specialOffers));
  }
  return search;
}

export function searchToCommonParameters(search: URLSearchParams): CommonParameters {
  const [
    subcampaigns,
    offers,
    disableRedirectPreview,
    customLandingUrl,
    ssp,
    techtoolsSwappableUploadId,
    specialOffers,
  ] = [
    search.get("subcampaigns"),
    search.get("offers"),
    search.get("disable_redirect_preview"),
    search.get("custom_landing_url"),
    search.get("ssp"),
    search.get("techtools_swappable_upload_id"),
    search.get("special_offers"),
  ];

  const commonParameters: CommonParameters = {};
  if (subcampaigns !== null) {
    commonParameters.subcampaigns = subcampaigns
      .split(",")
      .map((subcampaign: string) => subcampaign.trim())
      .filter((subcampaign: string) => !!subcampaign);
  }
  commonParameters.positionedOffers = extractPositionedOffers(search);
  if (offers != null) {
    commonParameters.unpositionedOffers = offers
      .split(",")
      .map((offer: string) => offer.trim())
      .filter((offer: string) => !!offer);
  }
  if (disableRedirectPreview !== null) {
    commonParameters.disableRedirectPreview = parseBooleanParameter(disableRedirectPreview);
  }
  if (customLandingUrl !== null) {
    commonParameters.customLandingUrl = customLandingUrl;
  }
  if (ssp !== null) {
    commonParameters.ssp = ssp;
  }
  if (techtoolsSwappableUploadId !== null) {
    commonParameters.techtoolsSwappableUploadId = techtoolsSwappableUploadId;
  }
  commonParameters.creativeTemplateParams = extractPrefixedParameters(search, "ctp_");
  if (specialOffers !== null) {
    commonParameters.specialOffers = parseSpecialOffers(specialOffers);
  }
  return commonParameters;
}

function extractPositionedOffers(search: URLSearchParams): { [key: number]: string } | undefined {
  const pattern = /^offer(\d+)$/;

  const positionedOffers: { [key: number]: string } = {};
  let any = false;
  for (const [key, value] of search.entries()) {
    const match = key.match(pattern);
    if (match === null) {
      continue;
    }
    const keyIndex = parseInt(match[1]);
    positionedOffers[keyIndex] = value;
    any = true;
  }

  if (!any) {
    return undefined;
  }
  return positionedOffers;
}

export interface Size {
  width: number;
  height: number;
}

const PARSE_SIZE_REGEX = /^(\d+)x(\d+)$/;

export function parseSizeString(value: string): Size | undefined {
  const matches = value.match(PARSE_SIZE_REGEX);
  if (!matches) {
    return undefined;
  }

  const width = parseInt(matches[1]);
  const height = parseInt(matches[2]);

  if (Number.isNaN(width) || Number.isNaN(height)) {
    return undefined;
  }

  return { width, height };
}

export function serializeSizeString(size: Size): string {
  return `${size.width}x${size.height}`;
}

export function parseSizesString(value: string): Size[] {
  return value
    .split(",")
    .map((item) => parseSizeString(item))
    .filter((item) => item !== undefined) as Size[];
}

export function serializeSizesString(sizes: Size[]): string {
  return sizes.map((size) => serializeSizeString(size)).join(",");
}

export function searchFromSizes(sizes: Size[] | undefined): URLSearchParams {
  const search = new URLSearchParams();
  if (sizes !== undefined) {
    search.set("sizes", serializeSizesString(sizes));
  }
  return search;
}

export function searchToSizes(search: URLSearchParams): Size[] | undefined {
  let sizes: Size[] | undefined = undefined;
  const sizesString = search.get("sizes");
  if (sizesString !== null) {
    sizes = parseSizesString(sizesString);
  }
  return sizes;
}

export function parseToNumberList(value: string): (number | null)[] {
  return value.split(",").map((v) => (v === "null" ? null : parseInt(v)));
}

export function serializeNumberList(value: (number | null)[]): string {
  return value.map((v) => v ?? "null").join(",");
}

export type RenderingOptions = Record<string, string>;

export type ChoosableRenderingOptions = Record<string, string[]>;

export interface SpecialOffer {
  identifier: string;
  url: string;
  overrideLandingUrl: boolean;
}

function parseSpecialOffers(value: string): SpecialOffer[] {
  let valueJson = null;
  try {
    valueJson = JSON.parse(value);
  } catch {
    return [];
  }

  if (!Array.isArray(valueJson)) {
    return [];
  }

  const specialOffers: SpecialOffer[] = [];
  for (const item of valueJson) {
    if (typeof item !== "object") {
      return [];
    }

    if (typeof item["identifier"] !== "string") {
      return [];
    }
    if (typeof item["url"] !== "string") {
      return [];
    }
    if (typeof item["overrideLandingUrl"] !== "boolean") {
      return [];
    }

    const specialOffer: SpecialOffer = {
      identifier: item["identifier"],
      url: item["url"],
      overrideLandingUrl: item["overrideLandingUrl"],
    };
    specialOffers.push(specialOffer);
  }
  return specialOffers;
}
function serializeSpecialOffers(value: SpecialOffer[]): string {
  return JSON.stringify(value);
}

export interface Preview {
  width: number;
  height: number;
  offersNumber: number | null;
  previewUrl: string;
}

export enum Company {
  RTBHOUSE = "RTBHOUSE",
  ADLOOK = "ADLOOK",
}

export interface CreativeFeatures {
  animation: string;
  button: boolean;
  name: boolean;
  price: boolean;
  slider: boolean;
}

export interface Creative {
  hash: string;
  status: string;
  company: Company;
  choosableRenderingOptions: ChoosableRenderingOptions;
  isPaapi: boolean | null;
  features: CreativeFeatures | null;
  previews: Preview[];
}

export async function getCreatives(hashes: string[], creativesFilters: CreativesFilters): Promise<Creative[]> {
  if (!hashes.length) {
    return [];
  }

  let search = new URLSearchParams();

  FILTERS.forEach((filter) => {
    search = filter.getRequestParams(search, creativesFilters);
  });

  search = mergeUrlSearchParams(
    search,
    searchFromCommonParametersSizesRenderingOptions(creativesFilters.commonParameters, creativesFilters.sizes),
  );

  const params = search.toString();
  return await get(
    `/creatives/${hashes.map((hash) => encodeURIComponent(hash)).join(",")}${params !== "" ? "?" + params : ""}`,
  );
}

export async function getPacks(
  advertiserHash: string,
  packIdentifiers: string[],
  creativesFilters: CreativesFilters,
): Promise<Creative[]> {
  if (!packIdentifiers.length) {
    return [];
  }

  let search = new URLSearchParams();

  FILTERS.forEach((filter) => {
    search = filter.getRequestParams(search, creativesFilters);
  });

  search = mergeUrlSearchParams(
    search,
    searchFromCommonParametersSizesRenderingOptions(creativesFilters.commonParameters, creativesFilters.sizes),
  );

  const params = search.toString();
  return await get(
    `/packs/${encodeURIComponent(advertiserHash)}/${packIdentifiers
      .map((packIdentifier) => encodeURIComponent(packIdentifier))
      .join(",")}${params !== "" ? "?" + params : ""}`,
  );
}

function searchFromCommonParametersSizesRenderingOptions(
  commonParameters: CommonParameters,
  sizes: Size[] | undefined,
): URLSearchParams {
  return mergeUrlSearchParams(searchFromCommonParameters(commonParameters), searchFromSizes(sizes));
}
