import { CreativesFilters, LocalParameters } from "components/common/Creatives";
import { Creative as CreativeData } from "repo/CreativeData";

import { createAnimationFilter } from "./AnimationFilter";
import { createBooleanFilter } from "./BooleanFilter";
import { PaapiFilter } from "./PaapiFilter";
import { createRenderingOptionsFilter } from "./RenderingOptionsFilter";

type RenderProps = {
  initialCreatives: CreativeData[];
  creativesFilters: CreativesFilters;
  updateCreativesFilters: (filters: CreativesFilters) => void;
};

export type Filter = {
  render: (props: RenderProps) => React.ReactNode;
  filterCreatives: (creative: CreativeData, creativesFilters: CreativesFilters) => boolean;
  searchFromLocalParameters: (search: URLSearchParams, localParameters: LocalParameters) => URLSearchParams;
  searchToLocalParameters: (search: URLSearchParams, localParameters: LocalParameters) => LocalParameters;
  getRequestParams: (search: URLSearchParams, creativesFilters: CreativesFilters) => URLSearchParams;
};

export const FILTERS: Filter[] = [
  PaapiFilter,
  createAnimationFilter("animation"),
  createBooleanFilter("slider", "slides-on"),
  createBooleanFilter("name", "name-on"),
  createBooleanFilter("button", "button-on"),
  createBooleanFilter("price", "price-on"),
  createRenderingOptionsFilter(["name-on", "button-on", "price-on", "slides-on", "animation"]),
];
